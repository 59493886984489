import z from 'zod';
import type { IQuery } from '../../lib/types';
import type { IPromise } from 'angular';

export const CustomerTransactionItemSchema = z.record(z.unknown());
export type ICustomerTransactionItem = z.infer<typeof CustomerTransactionItemSchema>;

const CustomerTransactionSchema = z
    .object({
        id: z.string(),
        timestamp: z.string(),
        items: z.array(CustomerTransactionItemSchema),
        transactionItems: z.unknown().optional(),
    })
    .catchall(z.unknown());

export type ICustomerTransaction = z.infer<typeof CustomerTransactionSchema>;

export const CustomerSchema = z
    .object({
        id: z.string(),
        customer_id: z.string().optional(),
        email: z.string().nullable(),
        name: z.string().nullable(),
        transactions: z.array(CustomerTransactionSchema).optional(),
    })
    .catchall(z.unknown());

export type ICustomer = z.infer<typeof CustomerSchema>;

export interface ICustomerStatsResponse {
    avg_item_count: number;
    avg_total_spent: number;
    avg_transaction_count: number;
    customer_count: number;
    item_count: number;
    total_spent: number;
    transaction_count: number;
}
export interface ICustomerStatsRequest {
    (query: IQuery): angular.IPromise<ICustomerStatsResponse[]>;
}

export interface ICustomerCountResponse {
    count: number;
}
export interface ICustomerCountRequest {
    (query: IQuery): angular.IPromise<ICustomerCountResponse[]>;
}

export interface ICustomerReportRequest {
    (query: IQuery): angular.IPromise<ICustomer[]>;
}

export interface ICustomerDetailsRequest {
    (query: IQuery): angular.IPromise<ICustomer[]>;
}

export interface IFindCurrenciesRequest {
    (): angular.IPromise<
        | string[]
        | {
              /** The currency ID */
              id: string;
          }[]
    >;
}

export interface IGetFullTimeSpanRequest {
    (query: IQuery): angular.IPromise<
        {
            max_timestamp: string;
            min_timestamp: string;
        }[]
    >;
}

export interface IMetricsFunnelRequest {
    (query: IQuery): angular.IPromise<Record<string, unknown>[] | Record<string, unknown>>;
}

export interface IMetricsFunneExportRequest {
    (query: IQuery): angular.IPromise<{ host: string; data: { id: string; type: string } }>;
}

export interface ICalendarType {
    /** The calendar type ID */
    id: string;
    /** The calendar type label */
    label?: string;
}
export interface IFindCalendarTypesRequest {
    (): angular.IPromise<ICalendarType[]>;
}

export interface IQueryServiceQueryAPI {
    query: {
        customerCount: ICustomerCountRequest;
        customerStats: ICustomerStatsRequest;
        customersReport: ICustomerReportRequest;
        getCustomerDetails: ICustomerDetailsRequest;
        findCurrencies: IFindCurrenciesRequest;
        findCalendarTypes: IFindCalendarTypesRequest;
        getFullTimeSpan: IGetFullTimeSpanRequest;
        metricsFunnel: IMetricsFunnelRequest;
        frye__productClassification: IMetricsFunneExportRequest;
    };
    getDescriptors: () => IPromise<unknown>;
}

export type IQueryServiceAPI = () => Promise<IQueryServiceQueryAPI>;
