import { z } from 'zod';
import { downloadFromURL } from '../../lib/dom/download';

export const CustomerReportExportSchema = z.object({
    host: z.string(),
    data: z.object({
        id: z.string(),
        type: z.string(),
    }),
});

export type ICustomerReportExport = z.infer<typeof CustomerReportExportSchema>;

export class QueryServiceExport {
    // `host` the query service host
    // `data` is whatever the server returned.
    static downloadAs(filename: string) {
        return (props: ICustomerReportExport) => {
            const dataType = encodeURIComponent(props.data.type);
            filename = encodeURIComponent(filename);
            const id = encodeURIComponent(props.data.id);
            const url = `${props.host}/exports/${dataType}/${filename}?id=${id}`;
            return downloadFromURL({ url });
        };
    }
}
