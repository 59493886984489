import _ from 'lodash';
import { IConfigObj, IQuery } from '../../lib/types';
import {
    CustomerSchema,
    ICustomer,
    ICustomerTransaction,
    ICustomerTransactionItem,
    IQueryServiceAPI,
} from '../../modules/services/query-service.types';
import { isObject } from '../../lib/utils';
import { IQueryMetrics } from '../main-controller';
import { IHierarchyService } from '../../modules/hierarchy';
import type { IPropertyDefinition } from '../../lib/config-hierarchy';
import type {
    IConfigCustomerDetailsProperties,
    IConfigCustomerDetailsProperty,
    IMetricDefinition,
    IQueryTableFilter,
    IQueryWhereTableFilter,
} from '../../lib/types';
import { logError } from '../../lib/analytics';
import { CustomerReportExportSchema, QueryServiceExport } from '../../modules/services/query-service-export';
import { AngularInjected } from '../../lib/angular';

type IdentifiableRecord = {
    id: string | number;
} & Record<string, unknown>;

type PropertiesQueryMappingKey = `property${number}`;
type PropertiesQueryMappingComponent = Record<PropertiesQueryMappingKey, { alias: string; field: string }>;
type MetricsQueryMappingComponent = Record<string, { alias: string; field: string }>;
type MetricsDefinitionQueryMappingComponent = Record<string, { fields: string[]; query: string }>;

interface QueryMapping {
    properties: PropertiesQueryMappingComponent;
    metrics: MetricsQueryMappingComponent;
    metricDefinitions?: MetricsDefinitionQueryMappingComponent;
}

const FETCH_CUSTOMERS_QUERY_MAPPING: QueryMapping = {
    properties: {
        property0: { alias: 'name', field: 'customers.name' },
        property1: { alias: 'customer_id', field: 'customers.id' },
        property2: { alias: 'accepts_marketing', field: 'customers.accepts_marketing' },
    },
    metrics: {
        demand_net_sales: { alias: 'total_spent', field: 'demand_net_sales' },
        demand_dollar_per_net_transaction: { alias: 'avg_spent', field: 'demand_dollar_per_net_transaction' },
        demand_net_transaction_count: { alias: 'transaction_count', field: 'demand_net_transaction_count' },
        demand_latest_order_timestamp: { alias: 'latest_visit', field: 'demand_latest_order_timestamp' },
    },
};

const CUSTOMER_COUNT_QUERY_MAPPING: QueryMapping = {
    properties: {
        property0: { alias: 'id', field: 'customers.id' },
    },
    metrics: {
        demand_net_sales_customer_count: { alias: 'count', field: 'demand_net_sales_customer_count' },
        demand_net_transaction_count: { alias: 'transaction_count', field: 'demand_net_transaction_count' },
        demand_dollar_per_net_transaction: { alias: 'avg_spent', field: 'demand_dollar_per_net_transaction' },
        demand_net_sales: { alias: 'total_spent', field: 'demand_net_sales' },
    },
};

const CUSTOMER_STATS_QUERY_MAPPING: QueryMapping = {
    properties: {
        property0: { alias: 'id', field: 'customers.id' },
    },
    metrics: {
        demand_units_per_net_transaction: { alias: 'avg_item_count', field: 'demand_units_per_net_transaction' },
        demand_average_net_sales_per_customer: {
            alias: 'avg_total_spent',
            field: 'demand_average_net_sales_per_customer',
        },
        demand_average_transactions_per_customer: {
            alias: 'avg_transaction_count',
            field: 'demand_average_transactions_per_customer',
        },
        demand_net_sales_customer_count: { alias: 'customer_count', field: 'demand_net_sales_customer_count' },
        demand_net_sales_units: { alias: 'item_count', field: 'demand_net_sales_units' },
        demand_net_sales: { alias: 'total_spent', field: 'demand_net_sales' },
        demand_net_transaction_count: { alias: 'transaction_count', field: 'demand_net_transaction_count' },
        demand_dollar_per_net_transaction: { alias: 'avg_spent', field: 'demand_dollar_per_net_transaction' },
    },
    metricDefinitions: {
        demand_average_transactions_per_customer: {
            fields: ['demand_net_transaction_count', 'demand_net_sales_customer_count'],
            query: 'demand_net_transaction_count / NULLIF(demand_net_sales_customer_count, 0)',
        },
        demand_average_net_sales_per_customer: {
            fields: ['demand_net_sales', 'demand_net_sales_customer_count'],
            query: 'demand_net_sales / NULLIF(demand_net_sales_customer_count, 0)',
        },
    },
};

const CUSTOMER_DETAILS_QUERY_MAPPING: QueryMapping = {
    properties: {
        property0: { alias: 'name', field: 'customers.name' },
        property1: { alias: 'customer_id', field: 'customers.id' },
        property2: { alias: 'id', field: 'order_items.sale_id' },
        property3: { alias: 'order_items_id', field: 'order_items.id' },
        property4: { alias: 'store_name', field: 'stores.name' },
        property5: { alias: 'item_name', field: 'items.name' },
    },
    metrics: {
        demand_net_transaction_count: { alias: 'transaction_count', field: 'demand_net_transaction_count' },
        demand_latest_order_timestamp: { alias: 'latest_visit', field: 'demand_latest_order_timestamp' },
        demand_net_sales: { alias: 'price', field: 'demand_net_sales' },
        demand_net_sales_at_msrp: { alias: 'msrp', field: 'demand_net_sales_at_msrp' },
        demand_net_sales_units: { alias: 'quantity', field: 'demand_net_sales_units' },
    },
};

function prepareMetricsFunnelQueryForCustomersPage(query: IQuery, queryMapping: QueryMapping): IQuery {
    const preparedQuery = _.cloneDeep(query);
    // should probably cache this reverse map somewhere, need to construct hash from queryMapping
    const aliasToEntity = buildAliasMap(queryMapping, true);
    preparedQuery.options = (() => {
        const property = (() => {
            const property: unknown = preparedQuery.options?.property;
            if (_.isNil(property)) return undefined;

            return Array.isArray(property) ? property : [property];
        })();
        const metrics = (() => {
            const metrics: unknown = preparedQuery.options?.metrics;
            if (_.isNil(metrics)) return undefined;

            return Array.isArray(metrics) ? metrics : [metrics];
        })();
        const definitions = preparedQuery.definitions ?? { ...(queryMapping.metricDefinitions ?? {}) };
        return {
            ...(preparedQuery.options ? preparedQuery.options : {}),
            ...(property ? { property } : { property: Object.values(queryMapping.properties).map(p => p.field) }),
            ...(metrics ? { metrics } : { metrics: Object.values(queryMapping.metrics).map(m => m.field) }),
            ...{ definitions },
        };
    })();

    if (isObject(preparedQuery.sort) && Object.keys(preparedQuery.sort).length > 0) {
        const sorting = Object.entries(preparedQuery.sort)[0];
        if (sorting) {
            const [sortField, sortOrder] = sorting;
            delete preparedQuery.sort;
            preparedQuery.sort = {
                field: aliasToEntity[sortField],
                order: sortOrder,
            };
        }
    }

    if (preparedQuery.where !== undefined && Object.keys(preparedQuery.where).length > 0) {
        // TODO (kevin): hacky, shouldn't assume customer properties, but for now...
        // const filters = Object.entries(preparedQuery.where).reduce((acc: IQueryTableFilterColumns, [key, value]) => {
        const filters = Object.entries(preparedQuery.where).reduce((acc: IQueryTableFilter, [key, value]) => {
            // gross hack, just for nowish
            if (key === 'name') {
                acc[key] = value;
                return acc;
            }
            if (!Object.keys(aliasToEntity).includes(key)) return acc;
            const aliasKey = aliasToEntity[key];
            if (!aliasKey) return acc;
            if (!/^property\d+$/.test(aliasKey)) return acc;
            acc[key] = value;
            return acc;
        }, {});

        const where = Object.entries(preparedQuery.where).reduce((acc: IQueryWhereTableFilter, [key, value]) => {
            if (!Object.keys(aliasToEntity).includes(key)) return acc;
            const aliasKey = aliasToEntity[key];
            if (aliasKey) {
                if (/^property\d+$/.test(aliasKey)) return acc;
                acc[aliasKey] = value;
                return acc;
            }

            return acc;
        }, {});

        delete preparedQuery.where;
        preparedQuery.where = where;

        // do we always want to remove existing filters? idk...
        delete preparedQuery.filters?.customers;
        preparedQuery.filters = preparedQuery.filters ?? {};
        preparedQuery.filters.customers = filters;
    }

    delete preparedQuery.comparison;
    return preparedQuery;
}

function isIdentifiableRecord(record: unknown): record is IdentifiableRecord {
    if (_.isNil(record)) return false;
    if (!isObject(record)) return false;
    if (!('id' in record) || !(typeof record['id'] === 'string' || typeof record['id'] === 'number')) return false;
    return true;
}

function buildAliasMap(queryMapping: QueryMapping, reverse?: boolean): Record<string, string> {
    if (reverse === undefined) reverse = false;
    const propertyKeys = new Set(Object.keys(queryMapping.properties));
    const metricKeys = new Set(Object.keys(queryMapping.metrics));
    if (new Set([...propertyKeys].filter(x => metricKeys.has(x))).size > 0) {
        throw new Error('Duplicate keys in query mapping between properties and metrics');
    }
    const flattenedMappings: QueryMapping['properties'] & QueryMapping['metrics'] = {
        ...queryMapping.properties,
        ...queryMapping.metrics,
    };
    return {
        ...Object.entries(flattenedMappings).reduce<Record<string, string>>((acc, [key, val]) => {
            acc[reverse ? val.alias : key] = reverse ? key : val.alias;
            return acc;
        }, {}),
    };
}

function aliasResults(
    queryResults: Record<string, unknown>[],
    resultAliases: Record<string, string>,
): Record<string, unknown>[] {
    const aliasedResults = queryResults.map((customerMetrics: Record<string, unknown>) => {
        const result: Record<string, unknown> = {};
        Object.entries(customerMetrics).forEach(([key, value]) => {
            result[resultAliases[key] ?? key] = value;
        });
        return result;
    });
    return aliasedResults;
}

export const CustomerStatsServiceInstance = () => [
    'QueryServiceAPI',
    function CustomerStatsService(QueryServiceAPI: IQueryServiceAPI) {
        return {
            fetch: (query: IQuery, queryMappingOverride?: QueryMapping) => {
                const queryMapping = queryMappingOverride ?? CUSTOMER_STATS_QUERY_MAPPING;
                const preparedQuery = prepareMetricsFunnelQueryForCustomersPage(query, queryMapping);
                preparedQuery.options ??= {};
                preparedQuery.options.includeSubtotals = false;
                const queryServiceAPIInstance = QueryServiceAPI();
                return queryServiceAPIInstance.then(api => {
                    return api.query.metricsFunnel(preparedQuery).then(data => {
                        if (!Array.isArray(data)) {
                            logError(new Error(`Invalid customer stats data: ${JSON.stringify(data)}`));
                            return {};
                        }
                        const aliases = buildAliasMap(queryMapping);
                        const aliasedResults = aliasResults(data, aliases);
                        return aliasedResults[0] ?? { customer_count: null };
                    });
                });
            },
        };
    },
];

export const CustomerCountServiceInstance = () => [
    'QueryServiceAPI',
    function CustomerCountService(QueryServiceAPI: IQueryServiceAPI) {
        return {
            fetch: (query: IQuery, queryMappingOverride?: QueryMapping) => {
                return QueryServiceAPI().then(api => {
                    const queryMapping = queryMappingOverride ?? CUSTOMER_COUNT_QUERY_MAPPING;
                    const preparedQuery = prepareMetricsFunnelQueryForCustomersPage(query, queryMapping);
                    preparedQuery.options ??= {};
                    preparedQuery.options.includeSubtotals = false;
                    return api.query.metricsFunnel(preparedQuery).then(customerCount => {
                        if (!Array.isArray(customerCount)) {
                            logError(new Error(`Invalid customer count data: ${JSON.stringify(customerCount)}`));
                            return 0;
                        }
                        const aliases = buildAliasMap(queryMapping);
                        customerCount = aliasResults(customerCount, aliases);
                        return customerCount[0]?.count ?? 0;
                    });
                });
            },
        };
    },
];

export const CustomersReportServiceInstance = () => [
    'QueryServiceAPI',
    'QueryMetrics',
    'Hierarchy',
    'CONFIG',
    function CustomersReportService(
        QueryServiceAPI: IQueryServiceAPI,
        QueryMetrics: IQueryMetrics,
        Hierarchy: IHierarchyService,
        CONFIG: IConfigObj,
    ) {
        function cleanString(value: unknown) {
            if (typeof value !== 'string' && typeof value !== 'number') return null;
            const trimmed = value.toString().trim();
            return trimmed.length === 0 ? null : trimmed;
        }

        function getCustomerLabel(customer: ICustomer) {
            const name = cleanString(customer.name);
            const email = cleanString(customer.email);
            const id = cleanString(customer.customer_id);
            return name ?? email ?? id ?? 'Unknown Customer';
        }

        const fetchCustomersReport = async (
            query: IQuery,
            queryMappingOverride?: QueryMapping,
        ): Promise<Record<string, unknown>[] | Record<string, unknown>> => {
            const queryServiceAPIInstance = QueryServiceAPI();
            return queryServiceAPIInstance.then(async api => {
                const queryMapping = queryMappingOverride ?? FETCH_CUSTOMERS_QUERY_MAPPING;
                const preparedQuery = prepareMetricsFunnelQueryForCustomersPage(query, queryMapping);
                preparedQuery.options ??= {};
                preparedQuery.options.includeTotals = false;
                preparedQuery.options.includeCustomerInformation = true;
                return await api.query.metricsFunnel(preparedQuery);
            });
        };

        return {
            fetch: async (query: IQuery, queryMappingOverride?: QueryMapping): Promise<Record<string, unknown>[]> => {
                const queryMapping = queryMappingOverride ?? FETCH_CUSTOMERS_QUERY_MAPPING;
                query.limit = query.limit ?? 50;
                query.offset = query.offset ?? 0;
                return fetchCustomersReport(query).then(customers => {
                    const aliases = buildAliasMap(queryMapping);
                    if (!Array.isArray(customers)) {
                        logError(
                            new Error(`Invalid customer records encountered. Sample: ${JSON.stringify(customers)}`),
                        );
                        return [];
                    }
                    customers = aliasResults(customers, aliases);
                    // We add a label to the customer record because the backend sometimes gives us
                    // empty strings, which is problematic we can't click these rows.

                    const isCustomer = (customer: unknown): customer is ICustomer => {
                        if (!isObject(customer)) return false;
                        if (!('customer_id' in customer)) return false;
                        if (customer['customer_id'] === null || customer['customer_id'] === undefined) return false;
                        return true;
                    };

                    return customers.map(customer => {
                        if (!isCustomer(customer)) {
                            logError(
                                new Error(`Invalid customer record encountered. Sample: ${JSON.stringify(customer)}`),
                            );
                            return {};
                        }
                        return { ...customer, label: getCustomerLabel(customer) };
                    });
                });
            },
            export: async (query: IQuery, queryMappingOverride?: QueryMapping) => {
                const queryMapping = queryMappingOverride ?? FETCH_CUSTOMERS_QUERY_MAPPING;
                query = _.cloneDeep(query);
                const [metricDefs, propDefs] = await Promise.all([QueryMetrics.fetch(), Hierarchy.fetch()]);
                const columnDefs: IMetricDefinition[] = [];
                const properties: IPropertyDefinition[] = [];
                const propertiesConfig = CONFIG.views?.customers?.properties ?? [];
                const propertyFields: string[] = (() => {
                    if (propertiesConfig.length > 0) {
                        const qualifiedId = (x: string) => `customers.${x}`;
                        return propertiesConfig.map((p: string) => qualifiedId(p));
                    }
                    return propDefs.all.filter(p => p.id.startsWith('customers.')).map(p => p.id);
                })();

                const metricFields = Object.values(queryMapping.metrics).map(m => m.field);
                propDefs.all.forEach(propDef => {
                    if (propertyFields.includes(propDef.id)) properties.push(propDef);
                });
                metricDefs.forEach(metricDef => {
                    if (metricFields.includes(metricDef.field)) columnDefs.push(metricDef);
                });
                delete query.limit;
                delete query.offset;
                query.type = 'xlsx';
                query.export = {
                    columnStyle: 'pivot',
                    columnDefs,
                    properties,
                };

                const exportQueryMapping: QueryMapping = {
                    properties: properties.reduce(
                        (acc: PropertiesQueryMappingComponent, pd: IPropertyDefinition, idx: number) => {
                            if (typeof pd.column !== 'string') {
                                logError(
                                    new Error(
                                        `Invalid property definition encountered while attempting to export. Sample: ${JSON.stringify(
                                            pd,
                                        )}`,
                                    ),
                                );
                                return acc;
                            }
                            acc[`property${idx}`] = { alias: pd.column, field: pd.id };
                            return acc;
                        },
                        {},
                    ),
                    metrics: queryMapping.metrics,
                };
                const customerReport = await fetchCustomersReport(query, exportQueryMapping);
                const parseCustomerReport = CustomerReportExportSchema.safeParse(customerReport);
                if (parseCustomerReport.success) {
                    const exportFn = QueryServiceExport.downloadAs('42-export-customers.xlsx');
                    return exportFn(parseCustomerReport.data);
                }
                return null;
            },
        };
    },
];

export type ICustomerAPIFactory = AngularInjected<typeof CustomerAPIFactory>;
export type ICustomerAPI = ReturnType<ICustomerAPIFactory>;

export const CustomerAPIFactory = () => [
    'QueryServiceAPI',
    function CustomerAPI(QueryServiceAPI: IQueryServiceAPI) {
        const queryServiceAPIInstance = QueryServiceAPI();

        return () =>
            queryServiceAPIInstance.then(api => {
                const buildQuery = (customerId: string): IQuery => {
                    return {
                        filters: {
                            customers: {
                                id: customerId,
                            },
                        },
                    };
                };

                return {
                    getDetails: async (
                        customerId: string,
                        queryMappingOverride?: IConfigCustomerDetailsProperties | null,
                    ): Promise<
                        { customer: ICustomer; customerExtraProperties: IConfigCustomerDetailsProperty[] } | undefined
                    > => {
                        if (!customerId) {
                            logError(new Error('Missing required customerId argument.'));
                            return Promise.resolve(undefined);
                        }

                        const query = buildQuery(customerId);
                        const queryMapping = (() => {
                            const customerDetailsMapping = _.cloneDeep(CUSTOMER_DETAILS_QUERY_MAPPING);
                            if (!queryMappingOverride) return customerDetailsMapping;

                            if (Array.isArray(queryMappingOverride.add)) {
                                const propertiesId = Object.values(customerDetailsMapping.properties).map(p => p.field);
                                queryMappingOverride.add.forEach((property, index) => {
                                    const indexOfProperty = propertiesId.findIndex(
                                        propertyId => propertyId === property.field,
                                    );
                                    if (indexOfProperty > -1) {
                                        if (property.label) {
                                            const queryProperty =
                                                customerDetailsMapping.properties[`property${indexOfProperty}`];
                                            if (queryProperty) queryProperty.alias = property.label;
                                        }
                                    } else {
                                        const nextIndex = propertiesId.length + index;
                                        customerDetailsMapping.properties[`property${nextIndex}`] = {
                                            alias: property.field,
                                            field: property.field,
                                        };
                                    }
                                });
                            }

                            return customerDetailsMapping;
                        })();

                        query.options = {};
                        query.options.includeTotals = false;
                        query.options.property = Object.values(queryMapping.properties).map(p => p.field);
                        query.options.metrics = Object.values(queryMapping.metrics).map(m => m.field);
                        query.options.includeCustomerInformation = true;
                        const aliases = buildAliasMap(queryMapping);
                        const response = await api.query.metricsFunnel(query);
                        if (!Array.isArray(response)) {
                            logError(
                                new Error(`Invalid customer records encountered. Sample: ${JSON.stringify(response)}`),
                            );
                            return undefined;
                        }
                        const result = aliasResults(response, aliases);
                        const salesById = result.reduce((acc: Record<string, ICustomerTransaction>, curr: unknown) => {
                            if (!isIdentifiableRecord(curr)) {
                                throw new Error(`curr is not an IdentifiableRecord:\n${JSON.stringify(curr, null, 2)}`);
                            }
                            if (!(typeof curr.price === 'number' || curr.price === null))
                                throw new Error('price is not null and is not a number');
                            if (!(typeof curr.quantity === 'number' || curr.quantity === null))
                                throw new Error('quantity is not null and is not a number');

                            const currentRecord = acc[curr.id];
                            if (
                                currentRecord &&
                                'items' in currentRecord &&
                                currentRecord.items !== undefined &&
                                !Array.isArray(currentRecord.items)
                            )
                                throw new Error('orderRecord.items is not an array');

                            const orderRecord: ICustomerTransaction = (() => {
                                const record = acc[curr.id] ?? { id: curr.id };
                                const extraProperties = (() => {
                                    if (queryMappingOverride) {
                                        if (Array.isArray(queryMappingOverride.add)) {
                                            return queryMappingOverride.add.reduce<Record<string, unknown>>(
                                                (acc, property) => {
                                                    acc[property.field] = curr[property.field];
                                                    return acc;
                                                },
                                                {},
                                            );
                                        }
                                    }
                                    return {};
                                })();

                                const items: ICustomerTransactionItem[] = (() => {
                                    const transactionItems: ICustomerTransactionItem[] = (() => {
                                        if ('items' in record && Array.isArray(record.items)) {
                                            return record.items;
                                        }
                                        return [];
                                    })();

                                    const item: ICustomerTransactionItem = {
                                        name: curr.item_name,
                                        image: curr.item_image,
                                        order_items_id: curr.order_items_id,
                                        price: curr.price,
                                        unit_price: (curr.price ?? 0) / (curr.quantity ?? 0),
                                        msrp: curr.msrp,
                                        quantity: curr.quantity,
                                        showQuantity: Math.abs(curr.quantity ?? 0) > 1,
                                    };

                                    transactionItems.push(item);
                                    return transactionItems;
                                })();

                                return {
                                    ...record,
                                    id: typeof curr.id === 'number' ? curr.id.toString() : curr.id,
                                    store_name: curr.store_name,
                                    timestamp: String(curr.latest_visit),
                                    email: curr.customer__email ?? 'N/A',
                                    name: curr.customer__name ?? 'N/A',
                                    ...extraProperties,
                                    items,
                                };
                            })();

                            acc[curr.id] = orderRecord;
                            return acc;
                        }, {});

                        const sales = Object.values(salesById);
                        const firstSale = sales[0];
                        if (!firstSale) throw new Error('No sales records found for customer');
                        const initialValue: ICustomer = {
                            id: firstSale.id,
                            name: typeof firstSale.name === 'string' ? firstSale.name : null,
                            email: typeof firstSale.email === 'string' ? firstSale.email : null,
                            total: 0,
                            itemsPurchased: 0,
                            transactions: [],
                        };
                        const customerSalesReport = sales.reduce<ICustomer>((acc: ICustomer, curr) => {
                            const salesReport =
                                Object.keys(acc).length === 0
                                    ? {
                                          id: curr.id,
                                          name: curr.name,
                                          email: curr.email,
                                          total: 0,
                                          itemsPurchased: 0,
                                          transactions: [],
                                      }
                                    : acc;

                            const parsedSales = CustomerSchema.safeParse(salesReport);
                            if (!parsedSales.success) {
                                throw new Error(
                                    `customerSalesReport is not an IdentifiableRecord: ${JSON.stringify(
                                        salesReport,
                                        null,
                                        2,
                                    )}`,
                                );
                            }

                            const customerSales: ICustomer = parsedSales.data;
                            if (customerSales.name !== curr.name || customerSales.email !== curr.email) {
                                throw new Error('Customer record mismatch in report build, bail!');
                            }
                            if (!Array.isArray(curr.items)) throw new Error('curr.items is not an array');
                            if (!Array.isArray(customerSales.transactions))
                                throw new Error('transactions is not an array');

                            curr.items.forEach(item => {
                                const price = 'price' in item && typeof item.price === 'number' ? item.price : 0;
                                const total = 'total' in curr && typeof curr.total === 'number' ? curr.total : 0;
                                const quantity =
                                    'quantity' in item && typeof item.quantity === 'number' ? item.quantity : 0;
                                const itemsPurchased =
                                    'itemsPurchased' in curr && typeof curr.itemsPurchased === 'number'
                                        ? curr.itemsPurchased
                                        : 0;
                                curr.total = total + price;
                                curr.itemsPurchased = itemsPurchased + quantity;
                            });
                            customerSales.transactions.push(curr);
                            return customerSales;
                        }, initialValue);

                        if (!Array.isArray(customerSalesReport.transactions))
                            throw new Error('transactions is not an array');

                        customerSalesReport.transactions = _.orderBy(
                            customerSalesReport.transactions,
                            'timestamp',
                            'desc',
                        ).map((transaction, index) => {
                            return { ...transaction, _index: index };
                        });

                        return {
                            customer: customerSalesReport,
                            customerExtraProperties: queryMappingOverride?.add ?? [],
                        };
                    },
                };
            });
    },
];
