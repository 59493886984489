import { ITransaction } from '../customer.types';
export const ScrollToSelectedTransactionDirective = () => [
    function ScrollToSelectedTransaction() {
        return {
            restrict: 'A',
            link: ($scope: angular.IScope, element: angular.IRootElementService) => {
                const $element = $(element);
                const scrollEvents = 'scroll mousedown DOMMouseScroll mousewheel keyup';

                $scope.$watch('transaction', (transaction: ITransaction | undefined) => {
                    if (!transaction) {
                        return;
                    }

                    const $transaction = $element.find(`#transaction-${transaction.id}`);

                    if ($transaction.length === 0) {
                        return;
                    }

                    const offset = (() => {
                        const transactionHeight = (() => {
                            const height = $transaction.height() ?? 0;
                            return height === 0 ? 0 : height / 2;
                        })();
                        const elementHeight = (() => {
                            const height = $element.height() ?? 0;
                            return height === 0 ? 0 : height / 2;
                        })();
                        return 1 + transactionHeight - elementHeight;
                    })();
                    const scrollTop = ($element.scrollTop() ?? 0) + $transaction.position().top + offset;
                    $element.stop().unbind(scrollEvents);
                    $element.animate({ scrollTop }, 500);
                    $element.bind(scrollEvents, (e: Event) => {
                        if (
                            (e instanceof KeyboardEvent ? e.which : 0) > 0 ||
                            e.type === 'mousedown' ||
                            e.type === 'mousewheel'
                        ) {
                            $element.stop().unbind(scrollEvents);
                        }
                    });
                });
            },
        };
    },
];
